import { USER_ROLES } from '../constants';
import IMAGES from '../img';

export const NAVBAR_HEIGHT = 110;
export const DRAWER_WIDTH = 240;
export const FOOTER_HEIGHT = 360;

export const INVENTORY_FILE_SHEET_NAMES = {
  box: 'Box-level indexing',
  file_irm: 'File-level indexing (completed by IRM)',
  file_customer: 'File-level indexing (completed by customer)',
};

export const INVENTORY_FILE_PATH = '/Inventory/';
export const IOD_FILE_PATH = '/IODs/';
export const INVENTORY_TEMPLATE_PATH =
  'Indexing Template/Express Storage Indexing Form.xlsx';
export const OBJECT_NOT_FOUND_CODE = 404;

export const MY_ACCOUNT_TABS = {
  accountInformation: {
    value: 'account-info',
    label: 'account_information_title',
  },
  serviceActivity: {
    value: 'service-activity',
    label: 'service_activity_title',
  },
  paymentHistory: {
    value: 'payment-history',
    label: 'payment_history_title',
  },
  userPermissions: {
    value: 'user-permissions',
    label: 'user_permissions_title',
  },
  userActivity: {
    value: 'user-activity',
    label: 'user_activity_title',
  },
};

export const ROUTES = {
  home: {
    path: '/',
    label: 'Home',
  },
  login: {
    path: '/login',
    label: 'Login',
  },
  documentCenter: {
    path: '/document-center',
    label: 'Document center',
  },
  myAccount: {
    path: '/my-account',
    label: 'My account',
  },
  serviceActivity: {
    path: `/my-account#${MY_ACCOUNT_TABS.serviceActivity.value}`,
    label: 'Service activity',
  },
  paymentHistory: {
    path: `/my-account#${MY_ACCOUNT_TABS.paymentHistory.value}`,
    label: 'Payment Managemnt',
  },
  userPermissions: {
    path: `/my-account#${MY_ACCOUNT_TABS.userPermissions.value}`,
    label: 'User permissions',
  },
  userActivity: {
    path: `/my-account#${MY_ACCOUNT_TABS.userActivity.value}`,
    label: 'User activity',
  },
  purchaseReceipt: {
    path: `/purchasereceipt`,
    label: 'Purchase Receipt',
  },
  accountInformation: {
    path: `/my-account#${MY_ACCOUNT_TABS.accountInformation.value}`,
    label: 'Account information',
  },
  orderSummary: {
    path: '/order-summary',
    label: 'Order summary',
  },
  adminPanel: {
    path: '/admin',
    label: 'Admin panel',
  },
  faqs: {
    path: '/help-center',
    label: 'Help Center',
  },
  mfa: {
    path: 'https://www.okta.com/identity-101/why-mfa-is-everywhere/',
    label: 'MFA',
  },
  privacyPolicy: {
    path: 'https://www.ironmountain.com/utility/legal/privacy-policy',
    label: 'footer_privacy_policy_link',
  },
  termsConditions: {
    path: 'https://drive.google.com/file/d/1UDotmAmNM1CNM6LLjF8G7RqQVvzdXssO/view',
    label: 'footer_terms_conditions_link',
  },
  learnMore: {
    path:
      process.env.REACT_APP_ENV === 'prod' ||
      process.env.REACT_APP_ENV === 'uat'
        ? 'https://express.ironmountain.com/shop?plan=oneTimeShred_shred'
        : 'https://stage.express.ironmountain.com/shop?plan=oneTimeShred_shred',
    label: 'ots_learn_more_link',
  },
  dxpWebApplication: {
    path:
      process.env.REACT_APP_ENV === 'prod' ||
      process.env.REACT_APP_ENV === 'uat'
        ? 'https://prod-dxp.dit01-insight-csp.com/'
        : 'https://dxp.dit01-insight-csp.com/',
    label: 'in_sight_portal',
  },
  recurringPayments: {
    path: `/recurringPayment`,
    label: 'Recurring Payment',
  },
};

export const LOCAL_STORAGE_KEYS = {
  referrer: 'referrer',
  user: 'user',
  oktaToken: 'oktaAccessToken',
  oktaIdToken: 'oktaIDToken',
  firebaseToken: 'firebaseToken',
};

export const SORT_ORDER = {
  asc: 'asc',
  desc: 'desc',
};

export const VARIANTS = {
  upcoming: 'upcoming',
  past: 'past',
};

export const SBC_BACKEND_ENDPOINTS = {
  auth: '/auth',
  accounts: '/accounts',
  products: '/products/{bundleTypeId}?options=all',
  price: '/pricing/price',
  validateOrder: '/orders/validate',
  validateOrderWithServiceAddress: '/orders/validateAddOn',
  orders: '/orders',
  createOrders: '/orders/addOn',
  orderSummary: '/orders/summary',
  users: '/users/all',
  inviteUser: '/users/{email}/create',
  removeUser: '/users/{email}/remove',
  updateRole: '/users/{email}/update',
  resendInvite: '/users/{email}/resendInvite',
  reactivateUser: '/users/{email}/reactivate',
  resetPassword: '/users/{email}/resetPassword',
  userActivity: '/users/activity',
  payment: '/payments/payWithPaymentToken',
  payWithCreditCard: '/payments/payWithCreditCard',
  payWithIsUniquePaymentMethodCredidCard:
    '/payments/payWithCreditCard?checkExisting=true',
  payWithIsUniquePaymentMethodBankAccount:
    '/payments/payWithBankAccount?checkExisting=true',
  payWithBankAccount: '/payments/payWithBankAccount',
  reauthPaymentMethod: '/support/reauthPaymentMethod',
  terminateAccount: '/support/terminateAccount',
  terminateService: '/support/terminateAndCharge',
  editCreditCard: '/payments/{paymentMethodId}/editCreditCard',
  removePaymentMethod: '/payments/{paymentMethodId}/remove',
  payWithDigitalWallet: '/payments/payWithDigitalWallet',
  refundIssue: '/support/captureRefund',
  supportUser: '/support/user',
  supportGetUsers: '/support/getUser',
  cancelProduct: '/support/cancelProduct',
  validateZip: '/other/zipCode',
  emailTemplate: '/other/emailTemplate?emailTemplateId={templateId}',
  addZipCode: '/zipCode/{code}?pull=yes',
  promoCode: '/other/promoCode',
  faqs: '/other/faq',
  serviceAddressAssociation: '/orders/serviceAddressAssociation',
  updateAddress: '/orders?mode=serviceAddressUpdate',
  serviceAddressList: '/support/address',
  bundlePriceLabels: '/support/priceLabels',
  serviceChanges: '/support/upgradeAndDowngrade',
  shopAddon: '/other/addOnManagement',
  productConfig: '/other/fetchProductConfig',
  processTaxForAdHocFee: '/support/processTaxForAdHocFee',
  adHocFeeRequest: '/support/adhocrequest',
  pauseAndRestartService: '/support/pauseAndRestartService',
  flexbinService: '/support/getFlexibleBinDates?accountId={accountId}',
  getProductContent: '/other/dashboardContent?name=Instructions',
  retryRecurringPayment: '/support/retryRecurringPayment',
  scheduleFlexbinPickup: '/orders/createFlexibleBinPickup',
  scheduleAdminFlexbinPickup: '/support/createFlexibleBinPickup',
  productSoldEmailNotification: '/support/product-sold-notices',
  addOnTermsAndConditionContent:
    '/other/termsAndConditionContent?name=destructionAuthorizationAgreement',
  getSkpOrderIds: '/support/getSkpOrderIds',
  updateSkpOrderNumberRequest: '/support/updateSkpOrderNumbers',
  getProductPrice: '/pricing/price',
  purchaseReciptRequest: '/other/getPaymentReceipt',
  fetchRecurringPayments: '/other/getBundlesToBeCharged',
  overrideRentalFee: '/support/overrideRentalFee',
  addIndexFormEntryRequestURL: '/inventory',
};

export const BUNDLE_OPTION_TYPES = {
  boxRetrieval: 'physicalBoxRetrieval',
  fileScan: 'documentScanRequest',
  storage: 'storage',
  boxReturns: 'boxReturns',
  boxPickUp: 'boxPickUp',
  recurringShred: 'shred32GalBin',
};

export const EMAIL_TEMPLATES = {
  to_customer: 'contactUsToCustomer',
  to_rep: 'contactUsToRep',
  oder_confirmation: 'oderConfirmation',
  re_auth_customer: 'reAuthPaymentSuccess',
  iod_upload_success: 'iodUploadSuccess',
};

export const MODAL_STATES = {
  main: 'main',
  success: 'success',
  failure: 'failure',
};

export const MODAL_INFO = {
  documentCenter: {
    success: {
      title:
        'Your file is being processed. Please check back later to view your inventory.',
      subtitle: '',
    },
    failure: {
      title: "Something doesn't look quite right.",
      subtitle:
        'We encountered an error while uploading your inventory file. Please try again or contact us if the problem persists.',
    },
  },
  contactUs: {
    success: {
      title: 'Thank you',
      subtitle:
        'Message successfully submitted. Our next available representative will reply as soon as possible.',
    },
    failure: {
      title: "Something doesn't look right",
      subtitle: 'Failed to send message, please try again.',
    },
  },
  reAuthPayment: {
    success: {
      title: 'Thank you',
      subtitle:
        'Your payment information has been updated successfully. Thank you!',
    },
    failure: {
      title: "Something doesn't look right",
      subtitle:
        'We were unable to process your payment information as entered. Please try again. Thank you!',
    },
  },
};

export const SHRED_ITEMS = [
  {
    icon: IMAGES.boxIcon,
    title: 'Select the number of boxes you need destroyed',
    subtitle: 'Up to 200 standard sized boxes (1.2 cubic feet)',
  },
  {
    icon: IMAGES.calenderIcon,
    title: 'Schedule your pick up',
    subtitle: 'A rep will reach out to you within 12 hours for scheduling',
  },
  {
    icon: IMAGES.boxOnHoldIcon,
    title: 'Prepare for pick up',
    subtitle:
      'Make sure your material is ready for the driver and can be placed in a bin for destruction',
  },
  {
    icon: IMAGES.certificateIcon,
    title: 'Certificate of disposal (upon request)',
    subtitle:
      'If you request a certificate, you’ll receive a certificate of destruction once the shredding is complete (available for viewing in your dashboard)',
  },
];

export const FLEX_BIN_ADD_ON_ITEMS = [
  {
    icon: IMAGES.boxIcon,
    title: 'Select the number of 65 gal bins you need',
  },
  {
    icon: IMAGES.calenderIcon,
    title: 'Schedule your bin delivery',
  },
  {
    icon: IMAGES.boxOnHoldIcon,
    title: 'Fill your bin at your leisure',
  },
  {
    icon: IMAGES.calenderIcon,
    title: 'Schedule your bin pickup within 30 days of delivery',
  },
  {
    icon: IMAGES.certificateIcon,
    title: 'Certificate of disposal (upon request)',
  },
];

export const SHRED_PICKUP_ITEM = {
  icon: IMAGES.calenderIcon,
  title: "We'll be in touch to schedule your pickup",
  subtitle: 'A rep will reach out to you within 12 hours for scheduling',
};

export const BANK_ACC_TYPES = [
  {
    value: 'Checking',
    label: 'Checking',
  },
  {
    value: 'Corporate checking',
    label: 'Corporate checking',
  },
  {
    value: 'Savings',
    label: 'Savings',
  },
];

export const US_STATES = [
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District of Columbia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];

export const CONTACT_METHODS = [
  { value: 'email', label: 'Email' },
  { value: 'phone', label: 'Phone' },
];
export const COMPANY_INDUSTRY = [
  { value: 'Banking', label: 'Banking' },
  { value: 'Education', label: 'Education' },
  { value: 'Energy,Mining,Utilities', label: 'Energy, Mining, Utilities' },
  { value: 'Finance', label: 'Finance' },
  { value: 'Government (Federal)', label: 'Government (Federal)' },
  {
    value: 'Government (State or Local)',
    label: 'Government (State or Local)',
  },
  { value: 'Healthcare', label: 'Healthcare' },
  { value: 'Insurance', label: 'Insurance' },
  { value: 'Legal', label: 'Legal' },
  { value: 'Management Services', label: 'Management Services' },
  { value: 'Manufacturing', label: 'Manufacturing' },
  { value: 'Media and Advertising', label: 'Media and Advertising' },
  { value: 'Pharma and Biotech', label: 'Pharma and Biotech' },
  { value: 'Real Estate', label: 'Real Estate' },
  { value: 'Retail and Wholesale', label: 'Retail and Wholesale' },
  { value: 'Software and Technology', label: 'Software and Technology' },
  { value: 'Telecommunication', label: 'Telecommunication' },
  { value: 'Tourism and Leisure', label: 'Tourism and Leisure' },
  {
    value: 'Transport, Logistics and Postal',
    label: 'Transport, Logistics and Postal',
  },
  { value: 'Other', label: 'Other' },
];

export const INV_LABELS = [
  { title: 'Serial Number', field: 'boxSerialNumber' },
  { title: 'Type', field: 'type' },
  {
    title: 'Status / Location',
    field: 'status',
  },
  {
    title: 'SKP Barcode #',
    field: 'skpBarcodeNumber',
  },
  {
    title: 'Major Description',
    field: 'majorDescriptor',
  },
  {
    title: 'Minor Description',
    field: 'minorDescriptor1',
  },
  {
    title: 'File Descriptor 1',
    field: 'fileDescriptor1',
  },
  {
    title: 'File Descriptor 2',
    field: 'fileDescriptor2',
  },
  {
    title: 'File Descriptor 3',
    field: 'fileDescriptor3',
  },
  { title: 'Creation Date', field: 'createDate' },
  { title: 'Destruction Date', field: 'destructionDate' },
];

export const ORDER_DATA_LIMIT = 30;
export const PAYMENTS_DATA_LIMIT = 20;

// TODO: Move this to db settings/common/refundReasons
export const REFUND_REASONS = [
  'Wrongfully Charged',
  'Canceled Service',
  'Customer Error',
  'Delivery Issue',
  'Dashboard Error',
  'Tax Exempt / Discount',
  'Upgrade / Downgrade',
  'Unhappy with Service Process',
  'Wanted Product Not Offered',
  'Other',
];

export const TERMINATION_REASONS = [
  'No longer needs service',
  'Unhappy with service',
  'Changing Services',
  'Moving Locations',
  'Other',
];

export const TERMINATION_REASONS_FOR_INSIGHT_ESSENTIAL = [
  'No longer needs service',
  'Unhappy with service',
  'Changing Services',
  'Moving Locations',
  'Does not wish to continue InSight trial',
  'Other',
];

export const PAST_ORDER_TYPES = ['completed', 'cancelled', 'refunded'];

export const PRODUCT_TABLE_HEADERS = [
  'Service Type',
  'Service Quantity',
  'Purchase Type',
  'Contract Type',
  'Frequency',
  'Contract Renewal Date',
  'Service Address',
  'Price',
  'Status',
  'Actions',
];

export const ADD_ON_HEADERS = [
  'Product',
  'Add On Product',
  'Contract Term',
  'Quantity',
  '',
];

export const ACCOUNT_ADDRESS_TABLE_HEADERS = [
  'company_address_label',
  'address_two_label',
  'city_label',
  'state_label',
  'zip_code_label',
];

export const BUNDLE_CHARGE_TYPE = {
  recurring: 'recurring',
  oneTime: 'oneTime',
};

export const NOTIFICATION_USER_TABLE_HEADERS = [
  'First name',
  'Last name',
  'Email',
  '',
];

export const USER_PERMISSION_TABLE_HEADERS = [
  'Name',
  'Email',
  'Access',
  'Status',
  'Edit',
];

export const USER_MANAGEMENT_TAB_ITEMS = [
  { title: 'all', isRole: true },
  { title: USER_ROLES.admin, isRole: true },
  { title: USER_ROLES.standard, isRole: true },
  { title: USER_ROLES.limited, isRole: true },
  { title: 'expired', isRole: false },
];

export const AD_HOC_FEE_TABLE_HEADERS = [
  'All',
  'Fee Name',
  'Quantity',
  'Unit Price',
  'Tax',
  'Amount',
];

export const UPDATE_ACCOUNT_KEYS = {
  firstName: 'First name',
  lastName: 'Last name',
  phone: 'Phone number',
  contactPref: 'Preferred contact method',
  customerName: 'Company name',
  industry: 'Company industry',
  notifyEmails: 'Notification User',
};

export const USER_ACTION_TYPES = {
  RESEND_INVITE: 'RESEND_INVITE',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  RESET_PASSWORD: 'RESET_PASSWORD',
  RE_ACTIVATE: 'RE_ACTIVATE',
  REMOVE_USER: 'REMOVE_USER',
  MAKE_ACCOUNT_OWNER: 'MAKE_ACCOUNT_OWNER',
  ROLE_UPDATE: 'ROLE_UPDATE',
};

export const ORDER_TERMS_AND_CONDTIONS = [
  'order_number',
  'order_date',
  'field_of_terms_and_conditions',
];

export const EMAIL_PURCHASE_NOTIFICATION_CONFIG = [
  'Product List',
  'Email DL',
  'Status',
  'Remove',
];

export const RECURRING_PICKUP_FREQUENCY_LIST = [
  {
    id: 'every4Weeks',
    label: '4 weeks',
  },
  {
    id: 'every12Weeks',
    label: '12 weeks',
  },
  {
    id: 'every6Months',
    label: '6 months',
  },
];

export const RECURRING_PICKUP_FREQUENCY_TYPES = {
  every4Weeks: 'every4Weeks',
  every12Weeks: 'every12Weeks',
  every6Months: 'every6Months',
};

export const RECURRING_PICKUP_FREQUENCY_LABELS = {
  every4Weeks: '4 weeks',
  every12Weeks: '12 weeks',
  every6Months: '6 months',
};

export const PRICE_INCLUDE_TAX_FLAG = 'yes';
export const PRICE_BREAKUP_FLAG = 'yes';

export const RECURRING_PAYMENT_TABLE_LABELS = [
  'Account Id',
  'Bundle Path',
  'Next Due Date',
  'Charge Status',
  'Number of Months',
];

export const OVERRIDE_RENTAL_FEE_ACTIONS = {
  SET_OVERRIDE_RENTAL: 'overrideRental',
  CLEAR_OVERRIDE_RENTAL: 'clearOverrideRental',
};

export const ACTIVITY_CATEGORY_LIST = {
  accountHistory: 'accountHistory',
  userActivity: 'userActivity',
  supportActivity: 'supportActivity',
};

export const FIELDS_TO_SHOW_1ST_LETTER_UPPERCASE_FOR_ACTIVITY_LOGS = [
  'roles',
  'emailType',
  'status',
  'mode',
  'action',
  'pickupFrequency',
  'frequency',
  'actionType',
  'newValue',
  'oldValue',
  'productType',
];

export const DEFAULT_USER_PERMISSION_TABS = [
  'admin',
  'standard',
  'limited',
  'expired',
];
